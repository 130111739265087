import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { take } from "rxjs/operators";
import * as _ from "lodash";

import { Constants } from "../../../constants/constants";
import { SharedService } from "../../../services/shared.service";

import { MediaConnectSource, MediaLiveInputDevice } from "../../../models/shared";
import { MediaConnectSourcesService } from "../mediaconnect-sources.service";
import { ModalService } from "../../../components/shared/modals/modal.service";
import { MixpanelService } from "src/app/services/mixpanel.service";
import { TitleService } from "../../../services/title.service";
import { AmazonAwsService } from "../../configuration/amazon-aws/amazon-aws.service";
import {
    AmazonAWS,
    AmazonAWSAvailabilityZone,
    AmazonAWSIAMRole,
    AmazonAWSSecurityGroup,
    AmazonAWSSubnet
} from "../../configuration/amazon-aws/amazon-aws";
import { ControlContainer, UntypedFormControl, NgForm, Validators } from "@angular/forms";

@Component({
    selector: "app-source-form-elemental-link",
    templateUrl: "./source-form.component.html",
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class SourceElementalLinkComponent implements OnInit, OnDestroy {
    source: MediaConnectSource;
    sources: MediaConnectSource[];
    sourceName: string;
    sourceNames: string[];

    existingSource: MediaConnectSource;

    action: string;
    loading = true;
    saving = false;
    constants = Constants;

    isEdit = false;
    isClone = false;

    awsAccounts: AmazonAWS[] = [];
    awsAccountRegionsLoading = false;
    awsRegions: { id: string; name: string }[] = [];

    awsAccountZonesLoading = false;
    awsZones: AmazonAWSAvailabilityZone[] = [];
    awsSubnets: (AmazonAWSSubnet & { name: string })[] = [];
    awsSubnetOptions: (AmazonAWSSubnet & { name: string })[] = [];

    awsIAMRolesLoading = false;
    awsIAMRoles: AmazonAWSIAMRole[] = [];
    awsSecurityGroups: AmazonAWSSecurityGroup[] = [];

    awsElementalLinks: MediaLiveInputDevice[] = [];
    awsElementalLinksLoading = false;

    private sourcesSubscription: Subscription;
    private awsAccountsSubscription: Subscription;

    tagsControl = new UntypedFormControl([], [Validators.required]);
    nameControl = new UntypedFormControl("", [
        Validators.required,
        Validators.minLength(2),
        Validators.pattern(Constants.validators.source_name),
        Validators.pattern(Constants.validators.no_blanc_start_or_end)
    ]);
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private ss: MediaConnectSourcesService,
        private sharedService: SharedService,
        private modalService: ModalService,
        private mixpanelService: MixpanelService,
        private titleService: TitleService,
        private aas: AmazonAwsService
    ) {
        // The ActivatedRoute dies with the routed component and so the subscription dies with it.
        this.route.paramMap.subscribe(params => {
            this.sourceName = params.get("name");
            this.action = params.get("action");
            if (this.sourceName) {
                this.source = Object.assign({}, this.ss.getCachedMediaConnectSource(this.sourceName));
                this.existingSource = _.cloneDeep(this.source);

                // Check if source found in cache, if not get sources and source
                if (this.sharedService.isEmptyObject(this.source) || !this.source.hasFullDetails) {
                    this.ss
                        .refreshMediaConnectSources(false)
                        .pipe(take(1))
                        .subscribe(async () => {
                            const source = this.ss.getCachedMediaConnectSource(this.sourceName);
                            await this.ss.refreshMediaConnectSource(source, true).toPromise();
                            this.source = Object.assign({}, this.ss.getCachedMediaConnectSource(this.sourceName));
                            this.existingSource = _.cloneDeep(this.source);
                            //
                            this.prepForm();
                            this.loading = false;
                        });
                } else {
                    this.loading = false;
                }
            } else {
                this.loading = false;
            }
        });
    }

    prepForm() {
        if (this.action) {
            this.tagsControl.setValue(this.source.resourceTags);
            if (this.action === "edit") {
                this.isEdit = true;
                this.nameControl.setValue(this.source.name);
            } else if (this.action === "clone") {
                this.isClone = true;
                this.source.name = "";
            }

            if (this.source.elemental_link_account_id) {
                this.updateAWSAccountRegions();
                this.updateAWSRegion();
            }
        }

        if (!this.source && !this.isClone && !this.isEdit) {
            this.source = new MediaConnectSource();
            this.resetForm();
        }

        // Set Title
        this.titleService.setTitle("SOURCE", this.action, this.source);
    }

    resetForm() {
        // MediaConnectSource
        this.source.latency = 4000;
        this.source.output_nic = "";
        this.source.broadcaster_id = null;
        this.source.feeder_id = null;
        this.source.autopull_mtu = null;
        this.source.freeze_detection_timeout_sec = 10;
        this.source.blank_detection_timeout_sec = 10;
        this.source.elemental_link_id = null;
    }

    async updateAWSAccountRegions() {
        this.awsRegions = [];

        if (this.source.elemental_link_account_id == null) return;

        this.awsAccountRegionsLoading = true;

        const regions = await this.aas.getAWSAccountRegions(this.source.elemental_link_account_id);
        if (regions) {
            const awsRegions = [];
            Object.entries(regions).forEach(([key, value]) => {
                awsRegions.push({ id: key, name: value });
            });
            this.awsRegions = awsRegions;
        }

        this.awsAccountRegionsLoading = false;
    }

    async updateAWSRegion() {
        if (this.source.elemental_link_account_id == null || this.source.elemental_link_region == null) return;
        this.awsElementalLinksLoading = true;

        this.awsElementalLinks = await this.ss.listElementalLinks(
            this.source.elemental_link_account_id,
            this.source.elemental_link_region
        );

        if (!this.awsElementalLinks.find(l => l.Id === this.source.elemental_link_id)) {
            this.source.elemental_link_id = null;
        }
        this.awsElementalLinksLoading = false;
    }

    ngOnInit() {
        // Sources
        this.ss.refreshMediaConnectSources(true);
        this.sourcesSubscription = this.ss.mediaconnectSources.subscribe((sources: MediaConnectSource[]) => {
            this.sources = sources;
        });

        this.ss.mediaconnectSources.pipe(take(1)).subscribe((sources: MediaConnectSource[]) => {
            this.sourceNames = sources.map(s => s.name);
            if (this.isEdit) this.sourceNames = this.sourceNames.filter(n => n !== this.sourceName);
        });

        // AWS Accounts
        this.aas.refreshAWSAccounts(true);
        this.awsAccountsSubscription = this.aas.awsAccounts.subscribe((awsAccounts: AmazonAWS[]) => {
            this.awsAccounts = awsAccounts;
        });

        this.prepForm();
    }

    ngOnDestroy() {
        this.sourcesSubscription.unsubscribe();
        this.awsAccountsSubscription.unsubscribe();
    }

    async onSubmit() {
        if (this.nameControl.invalid) return;
        if (this.tagsControl.invalid) return;

        this.saving = true;

        const sourceModel = {
            name: this.isEdit ? undefined : this.nameControl.value,
            protocol: "zixi-push", //  Backwards compatibility
            ingest_port: 2088,
            max_bitrate: this.source.max_bitrate,
            latency: this.source.latency,
            resource_tag_ids: _.map(this.tagsControl.value, "id"),
            alerting_profile_id: this.source.alertingProfile.id,
            report_scte_warnings: this.source.report_scte_warnings,
            process_scte_reports: this.source.process_scte_reports,
            muted: this.source.muted,
            elemental_link_account_id: this.source.elemental_link_account_id,
            elemental_link_region: this.source.elemental_link_region,
            elemental_link_id: this.source.elemental_link_id
        };

        if (this.isEdit) {
            const changedData = this.sharedService.getZixiObjDiff(sourceModel, this.existingSource, []);
            const isEmptyData = this.sharedService.isEmptyObject(changedData);

            if (!isEmptyData) {
                const updatedSource = await this.ss.updateMediaConnectSource(this.source, {
                    ...changedData,
                    restart_confirmed: false
                });
                const showPopupMessageDialog = updatedSource;

                // Restart Notice
                if (showPopupMessageDialog === true) {
                    await this.modalService.confirm(
                        "SAVE_RESTART",
                        "SOURCE",
                        async () => {
                            const updateAndRestartSource = await this.ss.updateMediaConnectSource(this.source, {
                                ...changedData,
                                restart_confirmed: true
                            });
                            if (updateAndRestartSource) {
                                this.saving = false;
                                this.mixpanelService.sendEvent("update & restart mediaconnect source", {
                                    updated: Object.keys(changedData)
                                });
                                this.router.navigate([Constants.urls.sources, "elemental_link", this.source.name]);
                            } else this.saving = false;
                        },
                        this.source.name
                    );
                    this.saving = false;
                } else if (updatedSource) {
                    this.saving = false;
                    this.mixpanelService.sendEvent("update elemental link source", {
                        updated: Object.keys(changedData)
                    });
                    this.router.navigate([Constants.urls.sources, "mediaconnect", this.source.name]);
                } else this.saving = false;
            } else {
                this.saving = false;
                this.router.navigate([Constants.urls.sources, "mediaconnect", this.source.name]);
            }
        } else {
            const result = await this.ss.addMediaConnectSource(sourceModel);
            if (result) {
                this.mixpanelService.sendEvent("create mediaconnect source");
                this.router.navigate([Constants.urls.sources, "mediaconnect", result.name]);
            } else this.saving = false;
        }
    }

    back() {
        this.router.navigate([Constants.urls.sources, "new"]);
    }

    cancel() {
        if (this.isEdit || this.isClone) {
            //  Details are displayed under mediaconnect path.
            this.router.navigate([Constants.urls.sources, "mediaconnect", this.existingSource.name]);
        } else this.router.navigate([Constants.urls.sources]);
    }

    done() {
        this.router.navigate([Constants.urls.sources]);
    }
}
