import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { Observable, map } from "rxjs";
import { urlBuilder } from "@zixi/shared-utils";
import { Breadcrumb } from "src/app/services/breadcrumbs.service";
import { TranslateService } from "@ngx-translate/core";
import { SourcesService } from "./sources.service";
import { MediaConnectSource, Source } from "src/app/models/shared";
import { MediaConnectSourcesService } from "./mediaconnect-sources.service";

@Injectable({
    providedIn: "root"
})
export class SourceBreadcrumbsResolverService implements Resolve<Breadcrumb[]> {
    constructor(private sourcesService: SourcesService, private translate: TranslateService) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Breadcrumb[]> | Observable<never> {
        return this.sourcesService.refreshSource(urlBuilder.decode(route.params["sourceId"]), false).pipe(
            map((source: Source) => [
                {
                    label: source.name,
                    url: state.url
                }
            ])
        );
    }
}

@Injectable({
    providedIn: "root"
})
export class MediaConnectSourceBreadcrumbsResolverService implements Resolve<Breadcrumb[]> {
    constructor(private sourcesService: MediaConnectSourcesService, private translate: TranslateService) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Breadcrumb[]> | Observable<never> {
        return this.sourcesService.refreshMediaConnectSources(false).pipe(
            map((sources: MediaConnectSource[]) => [
                {
                    label: route.params["name"],
                    url: state.url
                }
            ])
        );
    }
}

@Injectable({
    providedIn: "root"
})
export class MediaConnectSourceFormBreadcrumbsResolverService implements Resolve<Breadcrumb[]> {
    constructor(private sourcesService: MediaConnectSourcesService, private translate: TranslateService) {}

    resolve(route: ActivatedRouteSnapshot): Observable<Breadcrumb[]> | Observable<never> {
        return this.sourcesService.refreshMediaConnectSources(false).pipe(
            map((source: MediaConnectSource[]) => [
                {
                    label: this.translate.instant("SOURCES"),
                    url: "/" + route.url[0].path
                },
                {
                    label: route.params["name"],
                    url: "/" + route.url[0].path + "/" + route.url[1].path + "/" + route.url[2].path
                },
                {
                    label:
                        (route.params.action ? this.translate.instant(route.params.action.toUpperCase()) + " " : "") +
                        this.translate.instant("SOURCE")
                }
            ])
        );
    }
}

@Injectable({
    providedIn: "root"
})
export class SourceFormBreadcrumbsResolverService implements Resolve<Breadcrumb[]> {
    constructor(private sourcesService: SourcesService, private translate: TranslateService) {}

    resolve(route: ActivatedRouteSnapshot): Observable<Breadcrumb[]> | Observable<never> {
        return this.sourcesService.refreshSource(urlBuilder.decode(route.params["sourceId"]), false).pipe(
            map((source: Source) => [
                {
                    label: this.translate.instant("SOURCES"),
                    url: "/" + route.url[0].path
                },
                {
                    label: source.name,
                    url: "/" + route.url[0].path + "/" + route.url[2].path + "/" + route.url[3].path
                },
                {
                    label:
                        (route.params.action ? this.translate.instant(route.params.action.toUpperCase()) + " " : "") +
                        this.translate.instant("SOURCE")
                }
            ])
        );
    }
}
