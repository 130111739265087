import {
    Component,
    OnInit,
    OnDestroy,
    ViewChild,
    HostListener,
    ElementRef,
    AfterViewInit,
    ChangeDetectorRef
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { firstValueFrom, interval, Observable, Subscription } from "rxjs";
import { take } from "rxjs/operators";
import * as _ from "lodash";
import { urlBuilder } from "@zixi/shared-utils";

// Services
import { SourcesService, SourceDependencyError } from "../sources.service";
import { ModalService } from "../../../components/shared/modals/modal.service";
import { SharedService } from "../../../services/shared.service";
import { UsersService } from "../../account-management/users/users.service";
import { ClustersService } from "../../clusters/clusters.service";
import { ResizeService } from "../../../services/resize.service";
import { CookieService } from "ngx-cookie-service";

// Models
import { Constants } from "../../../constants/constants";
import { Broadcaster, FailoverSource, RecoveryState, Source, UserPermissions } from "../../../models/shared";
import { Cluster } from "../../clusters/cluster";
import { Tag } from "../../../models/shared";
import { MixpanelService } from "src/app/services/mixpanel.service";
import { TranslateService } from "@ngx-translate/core";
import { TitleService } from "../../../services/title.service";
import { GraphsService } from "src/app/services/graphs.service";
import { SourceTargetBroadcasterPreference } from "@zixi/models";
import { BroadcastersService } from "src/app/components/broadcasters/broadcasters.service";
import { TagsService } from "../../configuration/tags/tags.service";
import { UptimePipe } from "src/app/pipes/uptime.pipe";
import { NavigationService } from "src/app/components/navigation/navigation.service";
import { DeviceDetectorService } from "ngx-device-detector";
import { SourceLayouts } from "./source.layout";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DisasterRecoveryDialogComponent } from "src/app/components/shared/modals/disaster-recovery-dialog/disaster-recovery-dialog.component";

@Component({
    selector: "app-source",
    templateUrl: "./source.component.html"
})
export class SourceComponent extends SourceLayouts implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild("primaryDetailsArea", { read: ElementRef }) primaryDetailsArea: ElementRef;
    @ViewChild("secondaryDetailsArea", { read: ElementRef }) secondaryDetailsArea: ElementRef;

    @HostListener("window:resize", [])
    private onResize() {
        this.getDetailsAreaHeights();
    }

    urlBuilder = urlBuilder;
    constants = Constants;
    source: Source;
    sourceName: string;
    sourceId: number;
    resourceTags: Tag[];
    refreshing = false;

    maintenanceWarning = false;
    userPermissions: UserPermissions;
    isAdmin: boolean;
    isContentAnalysis: boolean;
    loadingDetails = true;
    inputClusters: Cluster[];
    targetBroadcaster: Observable<Broadcaster>;

    isWidgetFullyLoaded = false;
    isMultiSelect = false;
    initIsMultiSelect;
    recoveryState: RecoveryState;

    widgetsToRemoveOrAdd = [];

    private sourcesSubscription: Subscription;
    private navSubscription: Subscription;
    private splitterSubscription: Subscription;
    private resizeSubscription: Subscription;
    private refreshThumbnailSubscription: Subscription;

    primaryDetails = this.sourcePrimaryDetails;
    secondaryDetails = this.sourceSecondaryDetails;
    widgets = this.sourceWidgets;
    widgetHeaders = this.sourceWidgetHeaders;

    protected updateDetailContent(title: string) {
        switch (title) {
            // Primary
            case this.translatedNames.THUMBNAIL:
                return {
                    content: "-",
                    object: this.source
                };
            case this.translatedNames.BITRATE:
                return {
                    content: this.sharedService.getKBPSContent(this.source.status?.bitrate),
                    unit: "kbps"
                };
            case this.translatedNames.UP_TIME:
                return {
                    content: this.uptimePipe.transform(this.source.status?.up_time)
                };
            case this.translatedNames.TAGS:
                return {
                    content: "-",
                    object: this.source,
                    tags: this.source.resourceTags,
                    type: "source",
                    canEdit: () => this.canEdit(this.source)
                };
            case this.translatedNames.PRIORITY:
                return {
                    content: this.tagsService.isObjectVip(this.source.resourceTags) ? "Yes" : "No"
                };
            case this.translatedNames.INGEST_CLUSTER:
                return {
                    content: "-",
                    object: this.source
                };
            case this.translatedNames.PLAY:
                return {
                    content: "-",
                    object: this.source
                };
            case this.translatedNames.TARGET_BROADCASTER_S:
                return {
                    content: this.source.target_broadcaster_id ?? "",
                    object: this.source,
                    broadcaster: this.targetBroadcaster
                };
            // Secondary
            case this.translatedNames.TYPE:
                return {
                    content: this.source._frontData.typeColumn ?? ""
                };
            case this.translatedNames.HEALTH:
                return {
                    content: this.source.health?.healthScore != null && this.ss.isPushOrPull(this.source) ? "Yes" : "",
                    object: this.source
                };
            case this.translatedNames.TRANSCODING_PROFILE:
                return {
                    content: this.source.transcodeProfile?.name ?? "",
                    link:
                        "/" +
                        Constants.urls.transformation.transcoding_profiles +
                        "/" +
                        this.source.transcodeProfile?.name
                };
            case this.translatedNames.PID_MAPPING:
                return {
                    content: this.source.pid_mapping?.name ?? "",
                    link: "/" + Constants.urls.transformation.pid_mapping_profiles + "/" + this.source.pid_mapping?.name
                };
            case this.translatedNames.URL:
                return {
                    content: this.source.type === "file" && this.source.protocol === "http_file" ? this.source.url : "",
                    canCopy: () => true
                };
            case this.translatedNames.PUBLIC_OUTPUT:
                return {
                    content: this.source.allow_outputs ? this.source.public_url : this.translate.instant("DISABLED"),
                    canCopy: this.source.allow_outputs ? true : false
                };
            case this.translatedNames.LATENCY:
                return {
                    content: this.source.feeder_id ? this.source.latency : ""
                };
            case this.translatedNames.AUTO_PULL:
                return {
                    content: !this.source.disable_autopull
                        ? this.source.autopull_latency
                            ? this.source.autopull_latency
                            : this.translate.instant("DEFAULT")
                        : this.translate.instant("DISABLED"),
                    unit: !this.source.disable_autopull && this.source.autopull_latency ? "ms" : ""
                };
            case this.translatedNames.MAX_BITRATE:
                return {
                    content: this.source.feeder_id
                        ? this.sharedService.getKBPSContent(this.source.max_bitrate / 1000)
                        : this.source?.status?.maxbitrate
                        ? this.sharedService.getKBPSContent(this.source.status?.maxbitrate / 1000)
                        : "",
                    unit: "kbps"
                };
            case this.translatedNames.LOCATION:
                return {
                    content: (this.source.location?.ip || this.source.location?.address)?.display_name ? "-" : "",
                    object: this.source
                };
            case this.translatedNames.NETWORK:
                return {
                    content: this.source.location?.ip ? this.sharedService.getNetwork(this.source.location) : "",
                    maxWidth: 240
                };
            case this.translatedNames.SOURCE_IP:
                return {
                    content:
                        this.source.type !== "file" && this.source.status?.source_ip
                            ? this.source.status.source_ip
                            : "",
                    canCopy: () => true
                };
            case this.translatedNames.SOURCE_PASSWORD:
                return {
                    content:
                        this.source &&
                        this.source.type !== "file" &&
                        this.source.type !== "rtmp" &&
                        this.source.type !== "hls_pull" &&
                        ((this.source._frontData.cluster?.auth_param &&
                            this.source._frontData.cluster?.auth_mode === "password") ||
                            (this.source.password && this.source._frontData.cluster?.auth_mode !== "password")) &&
                        (!this.source._frontData.cluster?.auth_mode ||
                            this.source._frontData.cluster?.auth_mode === "zen" ||
                            this.source._frontData.cluster?.auth_mode === "password" ||
                            this.source._frontData.cluster?.auth_mode === "custom")
                            ? this.source._frontData.cluster?.auth_mode === "password"
                                ? this.source._frontData.cluster?.auth_param
                                : this.source.password
                            : "",
                    password: true
                };
            case this.translatedNames.INPUT:
                return {
                    content:
                        this.source.feeder_id ||
                        this.source.broadcaster_id ||
                        this.source.input_id ||
                        (this.source.transcode_profile_id && this.source.type !== "multiview") ||
                        this.source.Source ||
                        this.source._frontData.input_description
                            ? "-"
                            : "",
                    object: this.source
                };
            case this.translatedNames.ALERTING_PROFILE:
                return {
                    content: this.source.alertingProfile?.name ?? "",
                    link:
                        "/" + this.constants.urls.configuration.eventsManagement + "/" + this.source.alertingProfile?.id
                };
            case this.translatedNames.APPLICATION:
                return {
                    content: this.source?.status?.app ?? ""
                };
            case this.translatedNames.DEVICE:
                return {
                    content: this.source?.status?.device ?? ""
                };
            case this.translatedNames.BUILD:
                return {
                    content: this.source?.status?.build ?? ""
                };
            case this.translatedNames.ENCRYPTED:
                return {
                    content: this.source?.status?.encryption_description || "No"
                };
            case this.translatedNames.REMOTE_ID:
                return {
                    content: this.source?.status?.remote_id ?? ""
                };
            case this.translatedNames.RTP_PAYLOAD:
                return {
                    content: this.source?.status?.rtp_payload ?? ""
                };
            case this.translatedNames.CLOSED_CAPTION_PRESERVE:
                return {
                    content: this.source?.copy_closed_captions
                        ? this.translate.instant("ENABLED")
                        : this.translate.instant("DISABLED")
                };
            case this.translatedNames.ALERT_ON_PID_CHANGES:
                return {
                    content:
                        this.source?.monitor_pids_change && this.source.type !== "file"
                            ? this.translate.instant("ENABLED")
                            : !this.source?.monitor_pids_change && this.source.type !== "file"
                            ? this.translate.instant("DISABLED")
                            : ""
                };
            case this.translatedNames.CONTENT_ANALYSIS:
                return {
                    content: this.source?.content_analysis
                        ? this.translate.instant("ENABLED")
                        : this.translate.instant("DISABLED")
                };
            case this.translatedNames.TRACEROUTE_HISTORY:
                return {
                    content:
                        this.source?.traceroute_history && this.source.type !== "file"
                            ? this.translate.instant("ENABLED")
                            : !this.source?.traceroute_history && this.source.type !== "file"
                            ? this.translate.instant("DISABLED")
                            : ""
                };
            case this.translatedNames.LOG_SCTE_35:
                return {
                    content:
                        this.source.inputCluster.configure_scte_reports &&
                        this.source.type !== "file" &&
                        this.source.process_scte_reports
                            ? this.translate.instant("ENABLED")
                            : !this.source.inputCluster.configure_scte_reports &&
                              this.source.type !== "file" &&
                              this.source.process_scte_reports
                            ? this.translate.instant("DISABLED_ON_CLUSTER")
                            : ""
                };
            case this.translatedNames.SCTE_35_ALERTS:
                return {
                    content:
                        this.source?.report_scte_warnings && this.source.type !== "file"
                            ? this.translate.instant("ENABLED")
                            : !this.source?.report_scte_warnings && this.source.type !== "file"
                            ? this.translate.instant("DISABLED")
                            : ""
                };
            default:
                return {
                    content: "",
                    statusClass: ""
                };
        }
    }

    canVLC = false;

    hasPlayBtn() {
        return this.source.webrtc_mode !== "" || (this.source.view_url && this.canVLC);
    }

    protected addOrRemoveFromDetails() {
        const hasPlay = this.hasPlayBtn();
        const playIndex = this.primaryDetails.findIndex(details => details.title === this.translatedNames.PLAY);
        if (hasPlay) this.primaryDetails[playIndex].isHidden = false;
        else if (playIndex !== -1) this.primaryDetails[playIndex].isHidden = true;

        const thumbnailIndex = this.primaryDetails.findIndex(
            details => details.title === this.translatedNames.THUMBNAIL
        );
        if (this.source.hide_thumbnail) this.primaryDetails[thumbnailIndex].isHidden = true;
        else if (thumbnailIndex !== -1) this.primaryDetails[thumbnailIndex].isHidden = false;

        this.primaryDetails = [...this.primaryDetails];
    }

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        public ss: SourcesService,
        private modalService: ModalService,
        protected sharedService: SharedService,
        protected userService: UsersService,
        private clustersService: ClustersService,
        private resizeService: ResizeService,
        private mixpanelService: MixpanelService,
        protected translate: TranslateService,
        private titleService: TitleService,
        private tagsService: TagsService,
        protected gs: GraphsService,
        protected uptimePipe: UptimePipe,
        private broadcastersService: BroadcastersService,
        private changeDetectorRef: ChangeDetectorRef,
        private navigationService: NavigationService,
        private deviceService: DeviceDetectorService,
        private ngbModal: NgbModal
    ) {
        super(translate, gs, userService, sharedService);
        this.route.paramMap.subscribe(async params => {
            this.sourceName = params.get("name");
            const sourceId = urlBuilder.decode(params.get("sourceId"));

            if (this.sourceId !== sourceId) this.resetPage();

            this.sourceId = sourceId;

            if (this.sourceName && this.sourceId)
                this.source = this.ss.getCachedSource(this.sourceName, null, this.sourceId);

            if (!this.source) return this.cancel();

            this.refreshThumbnail();

            // Set Title
            this.titleService.setTitle("SOURCE", "", this.source);

            // resourceTags
            this.sharedService
                .getResourceTagsByType("source")
                .pipe(take(1))
                .subscribe((tags: Tag[]) => {
                    this.resourceTags = tags;
                    this.updateWidgetsToRemoveOrAdd();
                    this.addOrRemoveFromWidgets();
                });

            this.updateAllThings();
        });

        this.canVLC = ["windows", "mac"].includes(this.deviceService.os.toLocaleLowerCase());
    }

    updateAllThings() {
        this.updateWidgetsToRemoveOrAdd();
        this.addOrRemoveFromWidgets();
        this.addOrRemoveFromDetails();
        this.primaryDetails = this.getUpdatesDetailsContent(this.primaryDetails);
        this.secondaryDetails = this.getUpdatesDetailsContent(this.secondaryDetails);
        this.recoveryState = this.isPrimaryOrAlternative();
    }

    updateWidgetsToRemoveOrAdd() {
        this.widgetsToRemoveOrAdd = [
            {
                title: this.translatedNames.SOURCES,
                toHide: !this.source.failoverSources || this.source.failoverSources.length === 0
            },
            { title: this.translatedNames.CHANGES, toHide: !this.canEdit(this.source) },
            { title: this.translatedNames.BONDING, toHide: !(this.source.status?.links?.length > 0) },
            { title: this.translatedNames.SCTE_35, toHide: !this.source.process_scte_reports },
            {
                title: this.translatedNames.SHARED,
                toHide: !this.isSharedSource(this.source) || !this.canEdit(this.source)
            },
            { title: this.translatedNames.HEALTH, toHide: !this.source.health?.healthScore }
        ];
    }

    close() {
        this.router.navigate([Constants.urls.sources]);
    }

    config() {
        this.ss.sourceConfigHelp(this.source);
    }

    traceroute() {
        this.ss.sourceTracerouteModal(this.source);
    }

    toggleMute() {
        this.mixpanelService.sendEvent((this.source.active_mute ? "unmute" : "mute") + " source");
        this.ss.updateSource(this.source, {
            muted: !this.source.active_mute,
            muted_until: null,
            flapping: null
        });
    }

    async muteUntil(date: Date) {
        this.mixpanelService.sendEvent("mute " + "source" + " until: " + date.toISOString());
        await this.ss.updateSource(this.source, {
            muted: true,
            muted_until: date.toISOString(),
            flapping: null
        });
    }

    async refresh() {
        this.refreshing = true;
        await firstValueFrom(this.ss.refreshSource(this.sourceId, true));
        this.refreshing = false;
    }

    resetPage() {
        this.maintenanceWarning = false;
    }

    startThumbnailRefresh() {
        this.refreshThumbnailSubscription = interval(8000).subscribe(() => {
            this.refreshThumbnail();
        });
    }

    stopThumbnailRefresh() {
        this.refreshThumbnailSubscription.unsubscribe();
    }

    refreshThumbnail() {
        this.ss.refreshSourceThumbnail(this.sourceId);
    }

    async ngOnInit() {
        // local storage
        if (localStorage.getItem("isInterfaceLocked"))
            this.isLocked = localStorage.getItem("isInterfaceLocked") === "true" ? true : false;

        if (localStorage.getItem("isSourceMultiselect"))
            this.isMultiSelect = localStorage.getItem("isSourceMultiselect") === "true" ? true : false;

        this.navSubscription = this.navigationService.toggle$.subscribe(() =>
            setTimeout(() => this.getDetailsAreaHeights(), 0)
        );
        this.splitterSubscription = this.sharedService.splitterResized$.subscribe(() => this.getDetailsAreaHeights());
        this.resizeSubscription = this.resizeService.getCurrentSize.subscribe(x => {
            this.isMobile = x < 4;
        });

        // Start Thumbnail Refresh
        this.startThumbnailRefresh();

        this.sourcesSubscription = this.ss.sources.subscribe(async sources => {
            this.source = sources.find((s: Source) => s.id === this.sourceId);

            if (this.source && this.source.hasFullDetails) {
                if (
                    this.source.target_broadcaster_id &&
                    this.source.broadcaster_cluster_id &&
                    this.source.target_broadcaster_id !== -1 &&
                    this.source.target_broadcaster_id !== -2 &&
                    this.source.target_broadcaster_id !== -3 &&
                    this.source.target_broadcaster_id !== -4
                ) {
                    this.targetBroadcaster = this.broadcastersService.getTargetBroadcaster(
                        this.source.target_broadcaster_id
                    );
                }

                this.updateAllThings();
                this.loadingDetails = false;

                if (this.source.broadcaster_cluster_id && this.inputClusters) {
                    this.updateCluster();
                }
            }
        });

        this.userService
            .getCurrentUser()
            .pipe(take(1))
            .subscribe(user => {
                this.isAdmin = !!user.is_admin || !!user.is_objects_manager;
                this.updateWidgetsToRemoveOrAdd();
                this.addOrRemoveFromWidgets();
            });

        this.userService.userPermissions.pipe(take(1)).subscribe(perm => {
            this.userPermissions = perm;
            this.updateWidgetsToRemoveOrAdd();
            this.addOrRemoveFromWidgets();
        });

        // isContentAnalysis
        this.userService.isContentAnalysis.pipe(take(1)).subscribe(bool => {
            this.isContentAnalysis = bool;
        });

        // inputClusters
        this.clustersService
            .refreshClusters(true)
            .pipe(take(1))
            .subscribe(clusters => {
                this.inputClusters = clusters;
                if (
                    this.source &&
                    this.source.hasFullDetails &&
                    this.source.broadcaster_cluster_id &&
                    this.inputClusters
                ) {
                    this.updateCluster();
                }
            });

        // set initial layout
        await this.revertLayoutChanges();
        this.loadingDetails = false;
        this.isWidgetFullyLoaded = true;
        setTimeout(() => this.getDetailsAreaHeights(), 0);

        this.recoveryState = this.isPrimaryOrAlternative();
    }

    ngOnDestroy() {
        if (this.sourcesSubscription) this.sourcesSubscription.unsubscribe();
        this.navSubscription?.unsubscribe();
        this.splitterSubscription?.unsubscribe();
        this.resizeSubscription?.unsubscribe();
        this.stopThumbnailRefresh();
    }

    ngAfterViewInit(): void {
        this.loadingDetails = false;
        this.changeDetectorRef.detectChanges();
        this.getDetailsAreaHeights();
    }

    ///////////////////////////////////////////
    updateCluster() {
        const primaryCluster = this.inputClusters.find(c => c.id === this.source.primary_broadcaster_cluster_id);
        const broadcasters = primaryCluster?.broadcasters || [];

        const bxs = broadcasters?.filter(bx => {
            if (this.source.primary_target_broadcaster_id > 0)
                return this.source.primary_target_broadcaster_id === bx.id;
            if (this.source.primary_target_broadcaster_id === SourceTargetBroadcasterPreference.PrimaryOnly)
                return !bx.is_backup;
            if (this.source.primary_target_broadcaster_id === SourceTargetBroadcasterPreference.BackupOnly)
                return bx.is_backup;
            return true;
        });

        this.maintenanceWarning = bxs.length > 0 && bxs.every(bx => bx.maintenance);
    }

    cancel() {
        this.gotoSources();
    }

    gotoSources() {
        this.close();
    }

    async deleteSource() {
        await this.modalService.confirm(
            "DELETE",
            "SOURCE",
            async () => {
                const id = this.source.id;
                try {
                    const result = await this.ss.deleteSource(this.source, false);
                    if (result) {
                        this.mixpanelService.sendEvent("delete source", { id });
                        this.gotoSources();
                        return true;
                    } else return false;
                } catch (error) {
                    if (error instanceof SourceDependencyError) {
                        this.modalService.confirm(
                            "DELETE",
                            "SOURCE",
                            async () => {
                                const result2 = await this.ss.deleteSource(this.source, true);
                                if (result2) {
                                    this.mixpanelService.sendEvent("delete source", { id });
                                    return true;
                                } else return false;
                            },
                            this.source.name,
                            { warning: error.customError }
                        );
                    } else {
                        return false;
                    }
                }
            },
            this.source.name
        );
    }

    cloneSource(source: Source): void {
        const type: string = this.ss.getRoutingSourceType(source);
        this.router.navigate(urlBuilder.getSourceActionUrl(type, source.id, source.name, "clone"));
    }

    async toggle() {
        let action = "";
        const model = {
            is_enabled: this.source.is_enabled
        };
        if (this.source.is_enabled === 1) {
            action = "DISABLE";
            model.is_enabled = 0;
        } else {
            action = "ENABLE";
            model.is_enabled = 1;
        }

        let transcoded: boolean;
        if (this.source.type === "transcoded") transcoded = true;
        else transcoded = false;

        if (transcoded && this.source.is_enabled !== 1) {
            await this.modalService.confirm(
                action,
                "SOURCE",
                async igt => {
                    const m = {
                        is_enabled: true,
                        ignore_transcode_thresholds: igt
                    };
                    const result = await this.ss.updateSource(this.source, m);
                    if (result) {
                        this.mixpanelService.sendEvent(this.translate.instant(action).toLowerCase() + " source", {
                            id: this.source.id
                        });
                        return true;
                    } else return false;
                },
                this.source.name,
                {
                    checkbox: "IGNORE_TRANSCODE_THRESHOLDS"
                }
            );
        } else {
            await this.modalService.confirm(
                action,
                "SOURCE",
                async () => {
                    try {
                        const result = await this.ss.updateSource(this.source, model);
                        if (result) {
                            this.mixpanelService.sendEvent(this.translate.instant(action).toLowerCase() + " source", {
                                id: this.source.id
                            });
                            return true;
                        } else return false;
                    } catch (error) {
                        if (error instanceof SourceDependencyError) {
                            this.modalService.confirm(
                                action,
                                "SOURCE",
                                async () => {
                                    const result2 = await this.ss.updateSource(this.source, {
                                        ...model,
                                        override_blocking: true
                                    });
                                    if (result2) {
                                        this.mixpanelService.sendEvent(
                                            this.translate.instant(action).toLowerCase() + " source",
                                            {
                                                id: this.source.id
                                            }
                                        );
                                        return true;
                                    } else return false;
                                },
                                this.source.name,
                                { warning: error.customError }
                            );
                            return true;
                        } else {
                            return false;
                        }
                    }
                },
                this.source.name
            );
        }
    }

    shareSource(source: Source) {
        const mainObjectType = "source";
        this.modalService.openSharedPageDialog({
            sharedPageRoute: `${Constants.urls.shared.sources}/${source.id}`,
            refreshFunction: this.refreshSource,
            objectTranslateTitle: "SOURCE",
            mainObjectId: source.id,
            mainObjectType,
            permissions: [{ object_type: mainObjectType, readIds: [source.id] }],
            isCreateMode: true
        });
    }

    canEdit(source: Source) {
        return this.sharedService.canEditZixiObject(source, this.resourceTags, this.userPermissions);
    }

    async toggleComponentSourceLock(failoverSource: FailoverSource, lock: boolean) {
        await this.ss.toggleComponentSourceLock(failoverSource, lock);
    }

    resetTR101() {
        this.ss.resetTR101(this.source);
    }

    public refreshSource = () => {
        this.ss.refreshSource(this.source.id, true);
    };

    public isSharedSource(source: Source): boolean {
        return this.ss.isSharedSource(source);
    }

    refreshSourcePromise() {
        return firstValueFrom(this.ss.refreshSource(this.source.id, true));
    }

    async toggleDR() {
        if (this.recoveryState === RecoveryState.none) return;
        this.loadingDetails = true;

        const modal = this.ngbModal.open(DisasterRecoveryDialogComponent, {
            backdrop: "static",
            centered: true,
            size: "lg"
        });
        modal.componentInstance.objects = [{ id: this.source.id, type: "source" }];

        modal.result.then(() => {
            this.recoveryState = this.isPrimaryOrAlternative();
            this.loadingDetails = false;
        });
    }

    isPrimaryOrAlternative(): RecoveryState {
        return this.ss.getDisasterRecoveryState(this.source);
    }
}
